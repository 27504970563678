<template>
	<div class="page">
		<gc-header header-data="alarmParameter" />
		<div class="page-main">
			<div class="page-main-left">
				<gc-sub-title icon="icon-shebei1" text="配置列表" />
				<gc-custom-search
					v-if="userInfo.tenantType == 0"
					class="box-select"
					key-word="设备类型"
					type="select"
					width="100%"
					filterable
					:search.sync="deviceTypeId"
					:search-option="typeList"
				/>
				<gc-custom-search
					v-else
					class="box-select"
					key-word="客户类型"
					type="select"
					width="100%"
					:search.sync="userTypeId"
					:search-option="userTypeOptions"
					:search-option-key="{ label: 'name', value: 'defaultValue' }"
				/>
				<ul class="items">
					<li
						class="item"
						:class="{ active: form.eventId == item.eventId }"
						v-for="item in paramList"
						:key="item.eventId"
						@click="handleToggle(item)"
					>
						{{ (item.eventModel && item.eventModel.name) || '--' }}
					</li>
				</ul>
			</div>
			<div class="page-main-right" v-show="paramList.length">
				<gc-sub-title icon="icon-gaojing1" :text="itemTitle">
					<el-button v-if="canModify" class="btn-save" type="primary" plain circle @click="handleSave">
						保存
					</el-button>
				</gc-sub-title>
				<el-form ref="form" label-position="left" :model="form" :rules="formRules">
					<el-form-item label="是否配置该告警项">
						<el-radio-group v-model="form.eventSwitch" :disabled="!canModify">
							<el-radio :label="1">是</el-radio>
							<el-radio :label="0">否</el-radio>
						</el-radio-group>
					</el-form-item>
					<template v-if="comparisonName">
						<div class="title">配置信息</div>
						<el-form-item class="model-info" prop="comparisonValue" :label="comparisonName">
							<el-input
								type="text"
								v-model="form.comparisonValue"
								placeholder="请填写阈值"
								:disabled="!form.eventSwitch || !canModify"
							/>
							<span class="unit">{{ comparisonUnit }}</span>
						</el-form-item>
					</template>
					<div class="title">联动事件</div>
					<el-form-item>
						<el-checkbox-group v-model="form.pushIds" @change="handlePushIdsChange">
							<el-checkbox
								v-for="item in pushTypes"
								:key="item.defaultValue"
								:label="item.defaultValue"
								:name="item.sortCode"
								:disabled="!form.eventSwitch || !canModify || pagePushDisabled(item.defaultValue)"
							>
								{{ item.name }}
							</el-checkbox>
						</el-checkbox-group>
					</el-form-item>
				</el-form>
			</div>
			<gc-empty class="page-main-right" v-show="!paramList.length" />
		</div>
		<!-- <gc-empty v-else /> -->
	</div>
</template>

<script>
import { apiGetAlarmConfig, apiSetAlarmConfig } from '@/apis/alarm.api'
import { mapActions, mapGetters } from 'vuex'
import { ruleRequired } from '@/utils/rules'

export default {
	name: 'alarmParam',
	data() {
		return {
			typeList: [],
			deviceTypeId: null,
			userTypeId: null,
			paramList: [],
			form: {
				eventId: '',
				comparisonValue: '', // 比较值
				eventSwitch: 0, // 告警开关.0:关,1:开
				pushIds: [], // 推送事件id集合
				eventModel: {},
			},
			activeItem: {}, //当前活跃的告警项
		}
	},
	computed: {
		...mapGetters({
			dataList: 'dataList',
			isolationOrgId: 'isolationOrgId',
			userInfo: 'userInfo',
		}),
		canModify() {
			return this.$has('monitor:alarm-config:config')
		},
		pushTypes() {
			const { pushCreateBIds } = this.form?.eventModel
			const typeIds = pushCreateBIds?.split(',') || []
			const typeMap = this.dataList?.pushType || []
			return typeMap.filter(item => typeIds.includes(item.defaultValue))
		},
		itemTitle() {
			return this.form?.eventModel?.name || '报警明细'
		},
		comparisonName() {
			return this.form?.eventModel?.comparisonName
		},
		comparisonUnit() {
			return this.form?.eventModel?.comparisonUnit
		},
		formRules() {
			const { comparisonValueReg, comparisonValueRegMsg } = this.form?.eventModel || {}
			if (!this.comparisonName || !this.form.eventSwitch) return {}
			const valueRule = [ruleRequired('请填写阈值')]
			if (comparisonValueReg) {
				valueRule.push({
					pattern: comparisonValueReg,
					message: comparisonValueRegMsg,
					trigger: '',
				})
			}
			return { comparisonValue: valueRule }
		},
		userTypeOptions() {
			return this.$store.getters.dataList.userType || []
		},
	},
	watch: {
		deviceTypeId(newVal) {
			if (newVal) this.getAlarmConfig(newVal, true)
		},

		userTypeId: {
			handler(newVal) {
				if (newVal) this.getAlarmConfig(newVal, true)
			},
			immediate: true,
		},

		'form.eventSwitch'(newVal) {
			if (!newVal) {
				this.form.pushIds = []
				this.form.comparisonValue = this.activeItem?.eventModel?.comparisonDefaultValue || ''
				this.$refs.form.clearValidate()
			}
		},
		isolationOrgId() {
			Object.assign(this.$data, this.$options.data())
			this.getDeviceTypeList()
		},
	},
	methods: {
		...mapActions({
			getDeviceTypes: 'common/getDeviceTypeList',
			judgeNeedPickOrg: 'user/judgeNeedPickOrg',
		}),
		// 声音告警（id=8）和页面推送（id=3）联动，选择声音告警必须选择页面推送
		handlePushIdsChange(e) {
			if (e.includes('8') && !e.includes('3')) {
				this.form.pushIds.push('3')
			}
		},
		// 选择声音告警后页面推送默认选中不可编辑
		pagePushDisabled(id) {
			return id == 3 && this.form.pushIds.includes('8') && this.form.pushIds.includes('3')
		},
		// 获取设备类型下拉列表
		getDeviceTypeList() {
			console.log(this.userInfo)
			this.getDeviceTypes()
				.then((data = []) => {
					this.typeList = data
					if (data.length) {
						this.deviceTypeId = data[0].deviceTypeId
					}
				})
				.catch(err => {
					console.error(err)
				})
		},
		// 获取告警参数配置
		getAlarmConfig(typeId, init) {
			apiGetAlarmConfig(typeId)
				.then(({ list = [] }) => {
					this.paramList = list
					if (list.length) {
						const item = init ? list[0] : list.find(i => i.eventId == this.form.eventId)
						this.handleToggle(item)
					}
				})
				.catch(err => {
					console.error(err)
				})
		},
		handleToggle(item) {
			this.activeItem = item
			this.form = {
				...this.form,
				...item,
				comparisonValue: item.comparisonValue || item?.eventModel?.comparisonDefaultValue || '',
				pushIds: item.eventSwitch ? item.pushCreateBIds?.split(',') || [] : [],
			}
		},
		// 保存告警配置
		handleSave() {
			let flag = false
			this.$refs.form.validate(valid => {
				flag = valid
			})
			if (!flag) return
			const params = {
				...this.form,
				deviceTypeId: this.deviceTypeId,
				pushCreateBIds: this.form.pushIds?.join(','),
				userTypeId: this.userTypeId,
			}
			delete params.pushIds
			delete params.eventModel
			apiSetAlarmConfig(params)
				.then(() => {
					this.$message.success('保存成功！')
					this.getAlarmConfig(this.userInfo.tenantType === '0' ? this.deviceTypeId : this.userTypeId)
				})
				.catch(err => {
					console.error(err)
				})
		},
	},
	activated() {
		this.judgeNeedPickOrg().then(flag => {
			if (flag) {
				this.getDeviceTypeList()
				if (this.userInfo.tenantType !== '0') {
					this.userTypeId = this.userTypeOptions[0].defaultValue
				}
			}
		})
	},
}
</script>
<style lang="scss" scoped>
.page-main {
	&-left {
		.box-select {
			margin-top: 39px;
		}
		.items {
			margin-top: 30px;
			overflow-y: auto;
			height: calc(100% - 144px);
			.item {
				cursor: pointer;
				height: 40px;
				line-height: 40px;
				padding: 0 13px;
				background: #f0f0f0;
				font-weight: 400;
				color: #666666;
				border-radius: 8px;
				&.active {
					background: #ebedfa;
					color: #4d6bff;
				}
				+ .item {
					margin-top: 10px;
				}
			}
		}
	}
	&-right {
		::v-deep .el-form {
			padding: 25px;
			padding-bottom: 0;
			.el-form-item {
				display: flex;
				&__content {
					display: flex;
					align-items: center;
				}
			}
			.el-form-item__label {
				color: #ababab;
			}
			.el-radio-group {
				margin-left: 36px;
			}
			.title {
				margin-top: 30px;
				color: #ababab;
				position: relative;
				&::after {
					content: '';
					position: absolute;
					left: 60px;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
					border-bottom: 1px solid #eef0f3;
				}
			}
			.model-info {
				margin-top: 18px;
				.el-form-item__label {
					color: #666;
				}
				.el-input {
					max-width: 260px;
				}
			}
			.unit {
				margin-left: 10px;
				color: #ccc;
			}
			.el-checkbox {
				display: block;
				margin-top: 10px;
				width: 150px;
				&:first-of-type {
					margin-top: 20px;
				}
			}
		}
	}
}
::v-deep {
	.el-button.btn-save {
		width: 100px;
		height: 32px;
		border-radius: 16px !important;
	}
}
</style>
